import React from "react";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Facebook from "../../../../assets/images/facebook-512.svg";
import ContactUsImg from "../../../../assets/images/contactus-img2.png";

import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

const BeforeContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);
  return (
    <>
      <Header />

      <main className="main ContactUs">
        <Container>
          <h2 className="sectionTitle">Contact Us</h2>
          <div class="social-icons contact-us">
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <ul>
                  <h5>Get In Touch With Us</h5>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "javascript:void(0)"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <div className="img-box">
                        <img src={Whatsapp} alt="whatsapp" />
                      </div>
                      <span>WhatsAap</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.TELEGRAM != ""
                          ? appDetails?.TELEGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <div className="img-box">
                        <img src={Telegram} alt="telegram" />
                      </div>
                      <span>Telegram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.INSTATGRAM != ""
                          ? appDetails?.INSTATGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <div className="img-box">
                        <img src={Instagram} alt="instagram" />
                      </div>
                      <span>Instagram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel social-icon watsapp"
                      href={
                        appDetails?.FACEBOOK != ""
                          ? appDetails?.FACEBOOK
                          : "javascript:void(0)"
                      }
                      target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                    >
                      <div className="img-box">
                        <img src={Facebook} alt="facebook}" />
                      </div>
                      <span>Facebook</span>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={6} className="d-none d-md-block">
                <div className="contact-img">
                  <img src={ContactUsImg} alt="contact us" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </main>

      <BeforeLoginFooter />
    </>
  );
};

export default BeforeContactUs;
