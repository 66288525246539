import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TawkChat = () => {
  const customStyle = {
    visibility: {
      mobile: {
        position: "br",
        xOffset: 5,
        yOffset: 65,
      },
    },
  };
  return (
    <TawkMessengerReact
      propertyId="6639158907f59932ab3ca3d5"
      widgetId="1ht7fo952"
      customStyle={customStyle}
    />
  );
};
export default TawkChat;
